<template>
    <nav>
        <v-app-bar fixed fade-on-scroll elevation="2" height="48" class="white">
            <v-toolbar-title>
                <a href="/" style="text-decoration: none">
                    <v-img :src="require('@/assets/loginshield_word_with_logo_left_transparent_373x64.png')" class="mr-1 float-left" max-width="215" max-height="36"/>
                </a>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="blue"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <div v-if="!isAuthenticated || !currentAccount">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="blue" right>
                        <v-tab to="/" v-show="false"></v-tab>
                        <v-tab link @click="onLoginClicked"><span class="blue--text text--darken-2 pa-2">Login</span></v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y>
                        <template #activator="{ on }">
                            <v-btn text v-on="on">
                                <font-awesome-icon right class="blue--text" icon="bars" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="blue">
                            <v-tab to="/" v-show="false"></v-tab>
                            <v-tab link @click="onLoginClicked"><span class="blue--text text--darken-2 pa-2">Login</span></v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div>
            <div v-if="isAuthenticated && currentAccount">
                <v-tabs slider-color="blue">
                    <!-- Show toolbar buttons on large screens -->
                    <v-tab href="/" v-show="false"></v-tab>
                    <!-- Free User: Upgrade -->
                    <!--
                    <v-tab v-if="accountType === 'Free'" to="/subscribe/personal" v-show="$vuetify.breakpoint.mdAndUp">Upgrade</v-tab>
                    -->
                    <!-- Enterprise User: Users, Reports -->
                    <!-- <v-tab v-if="accountType === 'Enterprise'" to="/enterprise/users" v-show="$vuetify.breakpoint.mdAndUp">Users</v-tab> -->
                    <!-- <v-tab v-if="accountType === 'Enterprise'" to="/subscribe/reports" v-show="$vuetify.breakpoint.mdAndUp">Reports</v-tab> -->
                    <!-- All users -->
                    <!-- <v-tab :to="{ path: '/account', query: { acct: accountId } }" v-show="$vuetify.breakpoint.mdAndUp">Account</v-tab> -->
                    <v-tab :to="{ path: '/dashboard', query: { acct: accountId } }" v-show="$vuetify.breakpoint.mdAndUp">Dashboard</v-tab>
                    <v-menu offset-y>
                        <template #activator="{ on }">
                            <v-tab v-on="on">
                                <Avatar :attr="currentAccount" :size="36"/>
                                <font-awesome-icon icon="caret-down" class="pl-2 blue--text" fixed-width size="1x"/>
                            </v-tab>
                        </template>
                        <v-list class="ma-0 pa-0">
                            <div v-for="(account, idx) in accountList" v-bind:key="idx">
                                <v-list-item @click="selectAccount(account.id)" :class="{'lighten-5':true, 'blue':(account.id === currentAccount.id)}" style="padding-left: 8px;">
                                    <v-list-item-avatar tile style="margin-right: 8px;">
                                        <Avatar :attr="account" :size="32"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ account.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ account.type }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </div>
                            <div>
                                <v-tabs vertical slider-color="blue" class="menutabs">
                                    <v-tab href="/" v-show="false"></v-tab>
                                    <!-- Show these in menu if screen is too small to fit buttons -->
                                    <div v-show="$vuetify.breakpoint.smAndDown">
                                        <v-tab :to="{ path: '/dashboard', query: { acct: accountId } }">Dashboard</v-tab>
                                        <!-- <v-tab :to="{ path: '/account', query: { acct: accountId } }">Account</v-tab> -->
                                        <!--
                                        <v-tab v-if="accountType === 'Free'" to="/subscribe/personal">Upgrade</v-tab>
                                        -->
                                        <!-- <v-tab v-if="accountType === 'Enterprise'" to="/enterprise/users">Users</v-tab> -->
                                        <!-- <v-tab v-if="accountType === 'Enterprise'" to="/enterprise/reports">Reports</v-tab> -->
                                    </div>
                                    <!-- Show these in menu always -->
                                    <v-tab to='/preferences'>Preferences</v-tab>
                                    <v-divider></v-divider>
                                    <v-tab @click="onLogout">Logout</v-tab>
                                </v-tabs>
                            </div>
                        </v-list>
                    </v-menu>
                </v-tabs>
            </div>
        </v-app-bar>
        <v-snackbar v-model="isTakingTooLong" :timeout="-1" top color="blue" class="mt-0 pt-0">
            <span>Still loading...</span>
            <v-btn text class="blue white--text" @click="isTakingTooLong = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

// this.$store.commit('isLoading', true);
// this.$route.name   ->   so we can change menu items based on current route (current page)
// current route names:  front, about, privacy, software, terms, login, locked, me, device, test

export default {
    data() {
        return {
            timerId: null,
            isTakingTooLong: false,
        };
    },
    components: {
        Avatar,
    },
    methods: {
        async selectAccount(id) {
            const isRouteChanged = this.$route.name !== 'dashboard' || this.accountId !== id;
            await this.$store.dispatch('switchAccount', id);
            /*
            // see also front.vue
            if (this.currentAccount.type === 'Free') {
                this.$router.push('/dashboard/free');
            }
            if (this.currentAccount.type === 'Personal') {
                this.$router.push('/dashboard/personal');
            }
            if (this.currentAccount.type === 'Enterprise') {
                this.$router.push('/dashboard/enterprise');
            }
            */
            if (isRouteChanged) {
                this.$router.push({ path: '/dashboard', query: { acct: id } });
            }
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            // if (this.$route.name !== 'front') {
            //     this.$router.push('/');
            // }
            window.location.href = '/'; // return to public info site
        },
        onLoginClicked() {
            // show the login page, and capture the current page with query parameters so we can return user here after login
            this.$router.push({ path: '/login', query: { from: this.$route.fullPath } });
        },
    },
    watch: {
        async loadingMap() {
            if (this.isLoading) {
                if (!this.timerId) {
                    this.timerId = setTimeout(() => {
                        console.log('isTakingTooLong timer limit reached');
                        this.isTakingTooLong = true;
                    }, 10000);
                }
            } else if (this.timerId) {
                console.log('isTakingTooLong timer cleared');
                this.isTakingTooLong = false;
                clearTimeout(this.timerId);
                this.timerId = null;
            }
        },
    },
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            lockOnExit: (state) => state.session.lockOnExit,
            user: (state) => state.user,
            accountMap: (state) => state.accountMap,
            accountId: (state) => state.accountId,
            loadingMap: (state) => state.loadingMap,
        }),
        ...mapGetters({
            combinedAccountList: 'combinedAccountList', // combined array of sorted non-enterprise accounts, then sorted enterprise accounts
            currentAccount: 'account', // currently selected account object
            isLoading: 'isLoading',
        }),
        accountList() {
            return this.combinedAccountList.filter((item) => item.permit && item.permit.includes('edit'));
        },
        accountType() {
            return this.currentAccount ? this.currentAccount.type : '';
        },
    },
    created() {
        console.log('navbar created');
    },
};
</script>

<style>
    div.menutabs div[role="tablist"] { width: 100%; }
    div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
</style>
