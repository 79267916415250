<template>
    <v-footer dark padless>
        <v-row justify="center" no-gutters>
            <v-col cols="12" class="blue">
                <v-row justify="start" align="center" class="py-2 px-6 mr-0">
                    <div style="display: inline-block;">
                        <span class="text-body-2">Need Help?</span>
                        <a href="/contact/" class="text-body-2 ml-3 white--text">Contact Us</a>
                    </div>
                    <!-- <v-switch
                        class="ml-5 mt-0"
                        style="display: inline-block;"
                        v-model="isTutor"
                        color="white"
                        hide-details
                        >
                        <template #label>
                            <span style="margin-left: 4px">Tutorial</span>
                        </template>
                        </v-switch> -->
                </v-row>
            </v-col>
            <v-col cols="12" class="pa-0 dark" style="text-align: center">
                <v-btn elevation="2" class="text-none font-weight-light text-body-2" href="/notice/terms/">Terms of Use</v-btn>
                <v-btn elevation="2" class="text-none font-weight-light text-body-2" href="/notice/privacy/">Privacy Policy</v-btn>
                <v-btn elevation="2" class="text-none font-weight-light text-body-2" href="/software/">Software</v-btn>
            </v-col>
            <v-col cols="12" class="text-caption pa-0 dark font-weight-light" style="text-align: center">
                    Copyright &copy; 2019-2021 <strong>Cryptium Corporation</strong>. Patent pending. All rights reserved.
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {

        };
    },

    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        // https://vuex.vuejs.org/guide/forms.html
        isTutor: {
            get() {
                return this.$store.state.isTutorEnabled;
            },
            set(value) {
                this.$store.dispatch('setTutorState', value);
                if (value) {
                    // when enabling tutorial, scroll to top of window so user can see the content
                    // immediately without having to scroll up themselves
                    window.scrollTo(0, 0);
                }
            },
        },
    },
};
</script>
