// account types
const ACCOUNT_TYPE_ENTERPRISE = 'Enterprise'; // organizational
const ACCOUNT_TYPE_FREE = 'Free'; // individual
const ACCOUNT_TYPE_PERSONAL = 'Personal'; // individual
const ACCOUNT_TYPE_PRO = 'Pro'; // individual

// interaction types
const INTERACTION_WEBAUTHZ = 'webauthz';

export {
    ACCOUNT_TYPE_ENTERPRISE,
    ACCOUNT_TYPE_FREE,
    ACCOUNT_TYPE_PERSONAL,
    ACCOUNT_TYPE_PRO,
    INTERACTION_WEBAUTHZ,
};
