import Client from '@/sdk/loginshield';

const endpoint = new URL(window.location).origin;

const loginshieldConfig = {
    endpoint,
    serviceEndpoint: `${endpoint}/service`, // process.env.VUE_APP_SERVICE_ENDPOINT_URL || '/service';
    stripe: '/stripe', // process.env.VUE_APP_STRIPE_ENDPOINT_URL || '/stripe';
};

const clientInstance = new Client(loginshieldConfig);

export default clientInstance;
