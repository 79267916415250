<template>
    <v-app>
        <Navbar/>
        <v-main style="height: 100%;" class="blue lighten-5"> <!--  py-10 px-3 (putting this here prevents the front page from doing full-wdith background color changes in sections) -->
            <v-container style="margin-top: 16px;"> <!-- class="ma-0 pa-0"  -->
                <router-view></router-view>
            </v-container>
        </v-main>
        <Footer/>
    </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: 'App',

    components: {
        Navbar,
        Footer,
    },

    methods: {
        track(route) {
            console.log(`app.vue: tracking route ${route.fullPath}`);
            // track route changes that occur while using the app; this includes the initial page load
            const eventInfo = { action: 'route' }; // uri: route.fullPath;   route.fullPath is like "/login?t=1619544746090", but the track sdk already sends window.location.href with each event, which is the full path like https://tigercomet.x7.cryptium.tech/app/login?t=1619544746090   so removed it from the event info
            if (this.$route.query.campaignId) {
                this.$track.requestHeaders['Campaign-Id'] = this.$route.query.campaignId;
                localStorage.setItem('campaignId', this.$route.query.campaignId);
                console.log(`Found query, setting campaignId: ${this.$track.requestHeaders['Campaign-Id']}`);
            } else {
                const campaignId = localStorage.getItem('campaignId');
                if (typeof campaignId === 'string') {
                    this.$track.requestHeaders['Campaign-Id'] = campaignId;
                    console.log(`Found local storage, setting campaignId: ${this.$track.requestHeaders['Campaign-Id']}`);
                } else {
                    // use default campaignId for users who didn't arrive with a campaignId
                    this.$track.requestHeaders['Campaign-Id'] = '';
                }
            }
            console.log(`app.vue: track campaignId: ${this.$track.requestHeaders['Campaign-Id']}`);
            this.$track.event(eventInfo);
        },
    },

    watch: {
        $route(to) {
            this.track(to);
        },
    },

    created() {
        console.log('app created, initializing');
        const accountId = this.$route.query.acct;
        if (accountId) {
            this.$store.commit('accountId', accountId);
        }
        this.$store.dispatch('init');

        // call when tab is selected
        window.onfocus = () => {
            this.$store.dispatch('refresh');
        };
    },
};
</script>
